import React from 'react'
import Slider from 'react-slick'
import FooterSlider from './FooterSlider'
import ScrollToTop from './ScrollToTop'
import BackToTopButton from './BakctoTop'
import {  useNavigate } from 'react-router-dom'
import {Helmet} from "react-helmet"
const Service4 = () => {
  

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 770
  const navigate = useNavigate();
 // const location = useLocation()
  const settings = {
    dots: false,
    infinite: false,
    speed: 0,
    slidesToShow: 1,
     arrows:false,
    slidesToScroll: 1,
    draggable: false,
};

return (
<div className="content-wrapper">
<Helmet>
    <meta charSet="utf-8" />
    <title>Get Professional Sedan Services in Columbia, MD</title>
    <meta name="description" content="Hire premier sedan limo services through private executive airport transportation services in Columbia, MD. We are providing an Elite party bus shuttle for bachelor parties booking in Harford County, Baltimore County, Annapolis City, and Harford County." />
    <link rel="canonical" href="https://92limo.com/sedan-services" />
</Helmet>
<ScrollToTop/>
<BackToTopButton/>
  <div className="limoking-content">
    <div className="with-sidebar-wrapper">
    <Slider {...settings} swipe={true} >
                <div className="item">
                    <div className='carousel_content'>
                        <img alt='background-img' src='services/Sedan Services background image.jpg' style={{ height: isMobile ? '350px' : '650px', objectFit: 'fill' }} />
                        <div className='content_text' style={{backgroundColor:'rgba(0,0,0,0.6)', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 0, paddingTop: 10}}>
                            <h1
                                className="ms-layer msp-cn-2-1"
                                style={{textAlign:'center', fontSize: isMobile ? "24px" :'42px'}}
                                data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                                data-duration={375}
                                data-ease="easeOutQuint"
                                data-offset-x={0}
                                data-offset-y={-111}
                                data-origin="mc"
                                data-position="normal"
                            >
                                Hire Exceptional Sedan Services for a Smooth and Stylish Ride in Columbia, MD
                            </h1>
                            <div
                                className="ms-layer msp-cn-2-2"
                                style={{textAlign:'center', fontSize: isMobile ? "18px" :'32px'}}
                                data-effect="t(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)"
                                data-duration={375}
                                data-delay={300}
                                data-ease="easeOutQuint"
                                data-offset-x={0}
                                data-offset-y={-36}
                                data-origin="mc"
                                data-position="normal"
                            >
                                Elevate Your Journey with Effortless Elegance.
                            </div>
                            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20}} className='container'>
                  <button data-aos="fade-right"
                      data-aos-delay="500"
                       style={{width: '150px', height: '50px'}}
                                        className="limoking-button"
                                        onClick={() =>{ 
                                            navigate('/')
                                          }
                                        }
                                    >
                                        Book Now
                  </button>
                  <button data-aos="fade-left"
                data-aos-delay="500"
                       style={{width: '150px', height: '50px'}}
                                        className="limoking-button"
                                        onClick={() => navigate('/contact-us')}
                                    >
                                        Contact Us
                  </button>
                </div>
                            

                        </div>

                    </div>
                </div>
            </Slider>
     <section style={{padding: '50px', fontSize: '18px', textAlign:'center'}}  data-aos="fade-up" data-aos-delay="100">
     Discover unparalleled luxury and convenience with our premier sedan services in Columbia, MD. At 92 Transportation LLC, we specialize in luxury airport sedan service, ensuring punctual and comfortable transportation from and to the airport. Our sedan limo services in Baltimore County offers sophistication for weddings and special events, complete with professional chauffeurs and premium amenities. For everyday travel needs, our sedan cab service provides reliable and efficient point-to-point transportation throughout Howard County, and beyond. Experience the ultimate in comfort, style, and professionalism with every ride.
     </section>
     <section style={{display: 'grid',gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap: '50px', backgroundColor: 'rgba(213, 157, 35, 0.9)', padding: '50px'}}
        data-aos="fade-up"
        data-aos-delay="100"
        className=''>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img alt='section-1-img' src='services/Sedan Services section 01.jpg' style={{ height: isMobile ? '350px' : '100%', objectFit: 'cover', objectPosition:"center", width: '100%', }} />
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} >
              <h2 style={{color: 'white'}}>Reliable Sedan Service to the Airport for a Smooth and Stress-Free Journey in Columbia, MD</h2>
              <p style={{color: 'white', fontSize: '16px'}}>
              Arriving at the airport on time and in comfort is essential for any traveler. We specialize in providing reliable sedan service to ensure a seamless and stress-free journey in Columbia, MD. Our sedan airport transportation services are designed with your convenience in mind, offering luxurious vehicles and professional chauffeurs who prioritize punctuality and comfort.
              <br/>
              <br/>
              <h3 style={{color: 'white', textAlign: 'justify', fontSize: '16px', padding: 0, margin: 0}}><strong>Private Executive Sedan Service in Baltimore County</strong></h3>
For executives and business travelers, our private executive sedan service offers a blend of sophistication and efficiency. Whether you're heading to a meeting or catching a flight, our fleet of executive sedans ensures a smooth and comfortable ride. We understand the importance of timely arrivals and provide personalized service tailored to your schedule.
<br/>
<br/>
<h3 style={{color: 'white', textAlign: 'justify', fontSize: '16px', padding: 0, margin: 0}}><strong>Elite Sedan Services in Annapolis City</strong></h3>
Our elite sedan services cater to discerning individuals who seek luxury without compromise. From spacious interiors to state-of-the-art amenities, our sedans redefine travel standards. Whether you're traveling alone or with colleagues, our fleet is equipped to meet your highest expectations, ensuring a first-class experience from door to door.


              </p>
          </div>
     </section>
     <section style={{padding: '50px', fontSize: '18px', textAlign:'center'}}  data-aos="fade-up" data-aos-delay="100">
     <h3 style={{  fontSize: '16px', padding: 0, margin: 0}}><strong>Wedding Sedan Services in Harford County</strong></h3>
Let us enhance your special day with our dedicated wedding sedan services. Our elegant sedans and professional chauffeurs add a touch of luxury and style to your wedding transportation, ensuring you arrive in grace and comfort. We handle all logistics with precision, allowing you to focus on celebrating your union.
<br/>
<br/>
<h3 style={{ fontSize: '16px', padding: 0, margin: 0}}><strong>Unmatched Reliability and Convenience</strong></h3>
At 92 Transportation LLC, we pride ourselves on unmatched reliability and convenience. Whether you require transportation to BWI Airport, Dulles International Airport, or Reagan National Airport, our experienced drivers navigate traffic and logistics to ensure you reach your destination on time. We prioritize safety and customer satisfaction, making us the preferred choice for sedan services in Columbia, MD.
<br/>
<br/>     
From executive travels to wedding celebrations, our sedan services are tailored to meet your diverse needs with professionalism and care. Experience the difference with 92 Transportation LLC and enjoy a journey that's as memorable as your destination.
<br/>
<br/>
     Explore seamless sedan services in Columbia, MD, including hotel sedan service and transfer sedan to hotel. Experience luxury with our sprinter and mini party buses, perfect for any occasion in Howard County, Baltimore County, Annapolis City, and Harford County.
     <br/>
     <br/>
     <div style={{color: '#d59d23', fontWeight: 500, textAlign: 'center'}}>"Arrive in Style, Depart with Ease—Your Premier Choice for Sedan and Party Bus Services."</div>
     </section>
     <section style={{display: 'grid',gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap: '50px', backgroundColor: 'rgba(213, 157, 35, 0.9)', padding: '50px'}}
        data-aos="fade-up"
        data-aos-delay="100"
        className=''>
          
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} >
              <h2 style={{color: 'white'}}>Enjoy Your Late Night Out with Our Reliable Transportation Services in Columbia, MD</h2>
              <p style={{color: 'white', fontSize: '16px'}}>
              Planning a memorable late-night outing in Columbia, MD? Our transportation services are tailored for every occasion, including Bachelor Parties Booking in Baltimore County and other late-night adventures. Whether you're looking for limos and party buses, spacious party buses, or reliable drivers hire for Bachelor Parties in Annapolis City, we provide a fleet that meets your needs.
<br/>
<br/>
Our party bus shuttle ensures safe and convenient travel, making stops throughout Howard County, Baltimore County, Annapolis City, and Harford County. With hire a van with driver available, complete with professional drivers, your night out is in good hands. Trust us to enhance your experience with top-notch transportation services.
<br/>
<br/>
Experience worry-free travel—book your ride today and enjoy the best late-night transportation services in Columbia, MD!
              </p>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img alt='section-1-img' src='services/Sedan Services section 02.jpg' style={{ height: isMobile ? '350px' : '100%', objectFit: 'cover', objectPosition:"center", width: '100%', }} />
          </div>
     </section>
     <section style={{padding: '50px', textAlign: 'center', fontSize: '18px'}}  data-aos="fade-up" data-aos-delay="100">
     Planning a memorable late-night outing in Columbia, MD? Our transportation services are tailored for every occasion, including Bachelor Parties Booking and other late-night adventures. Whether you're looking for luxurious limos, spacious party buses, or reliable drivers for your Bachelor Party, we provide a fleet that meets your needs.
     <br/>
     <br/>
Our party bus shuttle ensures safe and convenient travel, making stops throughout Howard County, Baltimore County, Annapolis City, and Harford County. With vans available for hire, complete with professional drivers, your night out is in good hands. Trust us to enhance your experience with top-notch transportation services.
<br/>
     <br/>
     <div style={{color: '#d59d23', fontWeight: 500}}>"Elevate Your Late Night with Party Limo, Cheap Party Buses, Small Party Bus Hire, and Elite Party Bus Hire."</div>


     </section>
            <FooterSlider/>

    </div>
  </div>
  <div className="clear" />
</div>

  )
}

export default Service4