import React, { useState, useEffect } from 'react';
import { IoMdArrowRoundUp } from 'react-icons/io';

const ScrollTopButton = ({ isVisible }) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            // behavior: 'smooth',
        });
    };

    return (
        <button
            onClick={scrollToTop}
            style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                backgroundColor: '#d59d23',
                opacity: isVisible ? 0.7 : 0,
                transition: 'opacity 0.3s',
                border: 'none',
                borderRadius: '50%',
                width: '50px',
                height: '50px',
                padding: 0,
                cursor: 'pointer',
                zIndex: 20000,
                display: isVisible ? 'block' : 'none',
            }}
        >
            <IoMdArrowRoundUp style={{ fontSize: '24px', color: 'white' }} />
        </button>
    );
};

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            setIsVisible(window.pageYOffset > 300);
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return <ScrollTopButton isVisible={isVisible} />;
};

export default BackToTopButton;
