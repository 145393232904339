import React from 'react'
import Slider from 'react-slick'
import { useLocation ,useNavigate,useNavigation} from 'react-router-dom'

const FooterSlider = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const settings = {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        // arrows:true,
        slidesToScroll: 1,
    };

    const scrollToTop = () => {
        if(location.pathname === '/') {
            window.scrollTo({
                top: 400,
                left: 0,
                // behavior: 'smooth'
            });
        }else {
            navigate('/', { state: { scrolltoTop: true } })
        }
    };

    const isMobile = typeof window !== 'undefined' && window.innerWidth < 770
    return (
        <div>
            <section data-aos="fade-up" data-aos-delay="100" id="content-section-4">
                <div
                    className="limoking-parallax-wrapper limoking-background-image gdlr-show-all limoking-skin-about-us"
                    id="limoking-parallax-wrapper-3"
                    data-bgspeed={0}
                    style={{
                        backgroundImage: 'url("upload/service-bg1.jpg")',
                        paddingTop: 100,
                        paddingBottom: 60
                    }}
                >
                    <div className="container">
                        <div className="three-fifth columns">
                            <div className="limoking-item limoking-about-us-item limoking-with-divider">
                                <div className="about-us-title-wrapper">
                                    <h3 className="about-us-title">Only Qualified Chauffeurs</h3>
                                    <div className="about-us-title-divider" />
                                </div>
                                <div className="about-us-content-wrapper">
                                    <div className="about-us-content limoking-skin-content">
                                        <p>
                                            Our chauffeurs represent the pinnacle of professionalism and expertise. With rigorous screening and training, we ensure they meet our high standards. Trust us for a journey guided by skilled hands and exceptional service. Experience the difference with our qualified chauffeurs today.

                                        </p>
                                    </div>
                                    <a
                                        onClick={() => scrollToTop()}
                                        className="about-us-read-more limoking-button large"
                                    >
                                        Book Now
                                    </a>
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <div className="clear" />
            </section>
            {
                location.pathname === '/' && (
                    <section
                    //  data-aos="fade-up"
                    //     data-aos-delay="100"
                         id="content-section-10">
                        <div
                            className="limoking-color-wrapper"
                            style={{ backgroundColor: "#ffffff" }}
                        >
                            <div className="container" >
                                {/* <div className="six columns">
                                    <div className="limoking-item-title-wrapper limoking-item limoking-left limoking-small">
                                        <div className="limoking-item-title-container container">
                                            <div className="limoking-item-title-head-inner">
                                                <h3 className="limoking-item-title limoking-skin-title limoking-skin-border">
                                                    Video Tour
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="limoking-item limoking-content-item"
                                        style={{ marginBottom: 0 }}
                                    />
                                    <div className="limoking-video-item limoking-item">
                                        <video controls={false} autoPlay loop muted playsInline>
                                            <source src="upload/service-video-bg-n.webm" type="video/webm" />
                                        </video>
                                    </div>
                                    <div className="limoking-item limoking-content-item">
                                        <p>
                                            <strong>
                                                <span style={{ color: "#D59D23" }}>
                                                    Watch our fleet in this video
                                                </span>
                                            </strong>
                                        </p>
                                    </div>
                                </div> */}
                                <div style={{}} className="">
                                    <div className="limoking-testimonial-item-wrapper">
                                        <div className="limoking-item-title-wrapper limoking-item limoking-nav-container limoking-left limoking-small">
                                            <div className="limoking-item-title-container container">
                                                <div className="limoking-item-title-head-inner">
                                                    <h3 style={{textAlign: 'center', width: '100%'}} className="limoking-item-title limoking-skin-title limoking-skin-border">
                                                        Testimonial
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="limoking-item limoking-testimonial-item carousel modern-style">
                                            <div className="limoking-ux limoking-testimonial-ux">
                                                <div
                                                    className="flexslider"
                                                // data-type="carousel"
                                                // data-nav-container="limoking-testimonial-item"
                                                // data-columns={1}
                                                >
                                                    <Slider  {...settings} dots={false} >
                                                        <div style={{width: '80%'}} className='item'>
                                                            <div className="testimonial-item">
                                                                <div className="testimonial-item-inner limoking-skin-box">
                                                                    <div className="testimonial-item-content-wrapper">
                                                                        <div className="testimonial-content limoking-info-font limoking-skin-content">
                                                                            <p>
                                                                                I've used 92 Limo several times for business travel, and they have never disappointed. The booking process is smooth, and the drivers are always punctual and friendly. The vehicles are top-notch, providing a comfortable and stylish ride every time.
                                                                            </p>
                                                                        </div>
                                                                        <div className="testimonial-author-image limoking-skin-border">
                                                                            <img
                                                                                src="upload/testimonial-s-11-150x150.jpg"
                                                                                alt="testimonial-s-11-150x150"
                                                                                width={150}
                                                                                height={150}
                                                                            />
                                                                        </div>
                                                                        <div className="testimonial-info">
                                                                            <span className="testimonial-author limoking-skin-link-color">
                                                                                Jessica R.<span>,</span>
                                                                            </span>{" "}
                                                                            <span className="testimonial-position limoking-skin-info">
                                                                                General Manager, Coca Co.
                                                                            </span>
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='item'>
                                                            <div className="testimonial-item">
                                                                <div className="testimonial-item-inner limoking-skin-box">
                                                                    <div className="testimonial-item-content-wrapper">
                                                                        <div className="testimonial-content limoking-info-font limoking-skin-content">
                                                                            <p>
                                                                            92 Limo exceeded my expectations with their exceptional customer service. From the initial inquiry to the drop-off, everything was handled professionally. The limo was pristine, and our driver made sure we had everything we needed. I'll definitely be using their services again!
                                                                            </p>
                                                                        </div>
                                                                        <div className="testimonial-author-image limoking-skin-border">
                                                                            <img
                                                                                src="upload/testimonial-s-21-150x150.jpg"
                                                                                alt="testimonial-s-21-150x150"
                                                                                width={150}
                                                                                height={150}
                                                                            />
                                                                        </div>
                                                                        <div className="testimonial-info">
                                                                            <span className="testimonial-author limoking-skin-link-color">
                                                                                Sam Smith<span>,</span>
                                                                            </span>{" "}
                                                                            <span className="testimonial-position limoking-skin-info">
                                                                                CEO, The Klise
                                                                            </span>
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='item'>
                                                            <div className="testimonial-item">
                                                                <div className="testimonial-item-inner limoking-skin-box">
                                                                    <div className="testimonial-item-content-wrapper">
                                                                        <div className="testimonial-content limoking-info-font limoking-skin-content">
                                                                            <p>
                                                                            I have used this company a few times. And every time I do. It’s always the best experience. From pickup to drop off. They are top notch.  I even used their service for my parents and they were delighted by the service and experience.  Every time I travel to Maryland they are my go to provider.  I highly recommend them.
                                                                            </p>
                                                                        </div>
                                                                        <div className="testimonial-author-image limoking-skin-border">
                                                                            <img
                                                                                src="testimonial/d.png"
                                                                                alt="testimonial-s-21-150x150"
                                                                                width={150}
                                                                                height={150}
                                                                            />
                                                                        </div>
                                                                        <div className="testimonial-info">
                                                                            <span className="testimonial-author limoking-skin-link-color">
                                                                            Derrick Doose
                                                                            </span>{" "}
                                                                            {/* <span className="testimonial-position limoking-skin-info">
                                                                                CEO, The Klise
                                                                            </span> */}
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='item'>
                                                            <div className="testimonial-item">
                                                                <div className="testimonial-item-inner limoking-skin-box">
                                                                    <div className="testimonial-item-content-wrapper">
                                                                        <div className="testimonial-content limoking-info-font limoking-skin-content">
                                                                            <p>
                                                                            I am writing to express my utmost satisfaction with the exceptional limo service. Each experience has consistently exceeded my expectations in terms of professionalism, punctuality, and overall quality.
                                                                            </p>
                                                                        </div>
                                                                        <div className="testimonial-author-image limoking-skin-border">
                                                                            <img
                                                                                src="testimonial/jc.png"
                                                                                alt="testimonial-s-21-150x150"
                                                                                width={150}
                                                                                height={150}
                                                                            />
                                                                        </div>
                                                                        <div className="testimonial-info">
                                                                            <span className="testimonial-author limoking-skin-link-color">
                                                                            Jeffrey Clark
                                                                            </span>{" "}
                                                                            {/* <span className="testimonial-position limoking-skin-info">
                                                                                CEO, The Klise
                                                                            </span> */}
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='item'>
                                                            <div className="testimonial-item">
                                                                <div className="testimonial-item-inner limoking-skin-box">
                                                                    <div className="testimonial-item-content-wrapper">
                                                                        <div className="testimonial-content limoking-info-font limoking-skin-content">
                                                                            <p>
                                                                            Shah was very timely, prompt, polite and welcoming. A great experience. Highly recommend!!!
                                                                            </p>
                                                                        </div>
                                                                        <div className="testimonial-author-image limoking-skin-border">
                                                                            <img
                                                                                src="testimonial/b.png"
                                                                                alt="testimonial-s-21-150x150"
                                                                                width={150}
                                                                                height={150}
                                                                            />
                                                                        </div>
                                                                        <div className="testimonial-info">
                                                                            <span className="testimonial-author limoking-skin-link-color">
                                                                            Barbara Nolan
                                                                            </span>{" "}
                                                                            {/* <span className="testimonial-position limoking-skin-info">
                                                                                CEO, The Klise
                                                                            </span> */}
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='item'>
                                                            <div className="testimonial-item">
                                                                <div className="testimonial-item-inner limoking-skin-box">
                                                                    <div className="testimonial-item-content-wrapper">
                                                                        <div className="testimonial-content limoking-info-font limoking-skin-content">
                                                                            <p>
                                                                            Shah is our go-to driver for airport and other transports. He goes above and beyond to meet our needs. He is flexible and reliable.
                                                                            </p>
                                                                        </div>
                                                                        <div className="testimonial-author-image limoking-skin-border">
                                                                            <img
                                                                                src="/testimonial/j.png"
                                                                                alt="testimonial-s-21-150x150"
                                                                                width={150}
                                                                                height={150}
                                                                            />
                                                                        </div>
                                                                        <div className="testimonial-info">
                                                                            <span className="testimonial-author limoking-skin-link-color">
                                                                            Jamie Hanna
                                                                            </span>{" "}
                                                                            {/* <span className="testimonial-position limoking-skin-info">
                                                                                CEO, The Klise
                                                                            </span> */}
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Slider>
                                                    <ul className="slides">
                                                        <li className="testimonial-item">
                                                            <div className="testimonial-item-inner limoking-skin-box">
                                                                <div className="testimonial-item-content-wrapper">
                                                                    <div className="testimonial-content limoking-info-font limoking-skin-content">
                                                                        <p>
                                                                        We hired 92 Limo for a night out with friends, and it was a fantastic experience. The limo was luxurious, and our driver was amazing, making sure we had a great time. If you're looking for a reliable and classy limo service, look no further than 92 Limo!
                                                                        </p>
                                                                    </div>
                                                                    <div className="testimonial-author-image limoking-skin-border">
                                                                        <img
                                                                            src="upload/testimonial-s-11-150x150.jpg"
                                                                            alt="testimonial-s-11-150x150"
                                                                            width={150}
                                                                            height={150}
                                                                        />
                                                                    </div>
                                                                    <div className="testimonial-info">
                                                                        <span className="testimonial-author limoking-skin-link-color">
                                                                        David M<span>,</span>
                                                                        </span>{" "}
                                                                        <span className="testimonial-position limoking-skin-info">
                                                                            General Manager, Coca Co.
                                                                        </span>
                                                                    </div>
                                                                    <div className="clear" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="testimonial-item">
                                                            <div className="testimonial-item-inner limoking-skin-box">
                                                                <div className="testimonial-item-content-wrapper">
                                                                    <div className="testimonial-content limoking-info-font limoking-skin-content">
                                                                        <p>
                                                                            Cras justo odio, dapibus ac facilisis in,
                                                                            egestas eget quam. Curabit,ur blandit
                                                                            tempus porttitor. Praesent commodo cursus
                                                                            magna.
                                                                        </p>
                                                                    </div>
                                                                    <div className="testimonial-author-image limoking-skin-border">
                                                                        <img
                                                                            src="upload/testimonial-s-21-150x150.jpg"
                                                                            alt="testimonial-s-21-150x150"
                                                                            width={150}
                                                                            height={150}
                                                                        />
                                                                    </div>
                                                                    <div className="testimonial-info">
                                                                        <span className="testimonial-author limoking-skin-link-color">
                                                                            Sam Smith<span>,</span>
                                                                        </span>{" "}
                                                                        <span className="testimonial-position limoking-skin-info">
                                                                            CEO, The Klise
                                                                        </span>
                                                                    </div>
                                                                    <div className="clear" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="four columns">
                                    <div className="limoking-item-title-wrapper limoking-item limoking-left limoking-small">
                                        <div className="limoking-item-title-container container">
                                            <div className="limoking-item-title-head-inner">
                                                <h3 className="limoking-item-title limoking-skin-title limoking-skin-border">
                                                    Recent News
                                                </h3>
                                                <span className="limoking-nav-title">
                                                    <a
                                                        className="limoking-item-title-link limoking-info-font"
                                                        href="blog-full-with-right-sidebar.html"
                                                    >
                                                        Read All News
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-item-wrapper">
                                        <div className="blog-item-holder">
                                            <div className="clear" />
                                            <div className="twelve columns">
                                                <div className="limoking-item limoking-blog-widget">
                                                    <div className="limoking-ux limoking-blog-widget-ux">
                                                        <article
                                                            id="post-2255"
                                                            className="post-2255 post type-post status-publish format-standard has-post-thumbnail hentry category-post-slider tag-blog tag-life-style"
                                                        >
                                                            <div className="limoking-standard-style">
                                                                <div className="limoking-blog-thumbnail">
                                                                    <a href="#">
                                                                        <img
                                                                            src="upload/shutterstock_1579359681-400x400.jpg"
                                                                            alt="shutterstock_1579359681-400x400"
                                                                            width={400}
                                                                            height={400}
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <header className="post-header">
                                                                    <h3 className="limoking-blog-title">
                                                                        <a href="#">Sem Porta Mollis Parturi</a>
                                                                    </h3>
                                                                    <div className="limoking-blog-info limoking-info-font">
                                                                        <div className="blog-info blog-date limoking-skin-info">
                                                                            <span className="limoking-sep">/</span>
                                                                            <a href="#">21 Mar 2014</a>
                                                                        </div>
                                                                        <div className="blog-info blog-author limoking-skin-info">
                                                                            <span className="limoking-sep">/</span>By{" "}
                                                                            <a
                                                                                href="#"
                                                                                title="Posts by John Doe"
                                                                                rel="author"
                                                                            >
                                                                                John Doe
                                                                            </a>
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                    <div className="clear" />
                                                                </header>
                                                                <div className="clear" />
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="clear" />
                                            <div className="twelve columns">
                                                <div className="limoking-item limoking-blog-widget">
                                                    <div className="limoking-ux limoking-blog-widget-ux">
                                                        <article
                                                            id="post-2254"
                                                            className="post-2254 post type-post status-publish format-standard has-post-thumbnail hentry category-post-slider tag-blog tag-life-style"
                                                        >
                                                            <div className="limoking-standard-style">
                                                                <div className="limoking-blog-thumbnail">
                                                                    <a href="#">
                                                                        <img
                                                                            src="upload/shutterstock_1746421911-400x400.jpg"
                                                                            alt="shutterstock_1746421911-400x400"
                                                                            width={400}
                                                                            height={400}
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <header className="post-header">
                                                                    <h3 className="limoking-blog-title">
                                                                        <a href="#">Nullam Lorem Mattis Purus</a>
                                                                    </h3>
                                                                    <div className="limoking-blog-info limoking-info-font">
                                                                        <div className="blog-info blog-date limoking-skin-info">
                                                                            <span className="limoking-sep">/</span>
                                                                            <a href="#">21 Mar 2014</a>
                                                                        </div>
                                                                        <div className="blog-info blog-author limoking-skin-info">
                                                                            <span className="limoking-sep">/</span>By{" "}
                                                                            <a
                                                                                href="#"
                                                                                title="Posts by John Doe"
                                                                                rel="author"
                                                                            >
                                                                                John Doe
                                                                            </a>
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                    <div className="clear" />
                                                                </header>
                                                                <div className="clear" />
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="clear" />
                                            <div className="twelve columns">
                                                <div className="limoking-item limoking-blog-widget">
                                                    <div className="limoking-ux limoking-blog-widget-ux">
                                                        <article
                                                            id="post-2249"
                                                            className="post-2249 post type-post status-publish format-standard has-post-thumbnail hentry category-post-slider tag-blog tag-life-style"
                                                        >
                                                            <div className="limoking-standard-style">
                                                                <div className="limoking-blog-thumbnail">
                                                                    <a href="#">
                                                                        <img
                                                                            src="upload/shutterstock_1417049411-400x400.jpg"
                                                                            alt="shutterstock_1417049411-400x400"
                                                                            width={400}
                                                                            height={400}
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <header className="post-header">
                                                                    <h3 className="limoking-blog-title">
                                                                        <a href="#">Nibh Sem Sit Ullamcorper</a>
                                                                    </h3>
                                                                    <div className="limoking-blog-info limoking-info-font">
                                                                        <div className="blog-info blog-date limoking-skin-info">
                                                                            <span className="limoking-sep">/</span>
                                                                            <a href="#">21 Mar 2014</a>
                                                                        </div>
                                                                        <div className="blog-info blog-author limoking-skin-info">
                                                                            <span className="limoking-sep">/</span>By{" "}
                                                                            <a
                                                                                href="#"
                                                                                title="Posts by John Doe"
                                                                                rel="author"
                                                                            >
                                                                                John Doe
                                                                            </a>
                                                                        </div>
                                                                        <div className="clear" />
                                                                    </div>
                                                                    <div className="clear" />
                                                                </header>
                                                                <div className="clear" />
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="clear" />
                            </div>
                        </div>
                        <div className="clear" />
                    </section>
                )
            }

            <section data-aos="fade-up" data-aos-delay="100" id="content-section-8">
                <div
                    className="limoking-color-wrapper gdlr-show-all no-skin"
                    style={{
                        backgroundColor: "#f1f1f1",
                        paddingTop: 20,
                        paddingBottom: 10
                    }}
                >
                    <div className="container">
                        <div className="limoking-banner-item-wrapper">
                            <div
                                className="limoking-banner-images limoking-item"
                                style={{ marginBottom: 0 }}
                            >
                                <div
                                    data-effect="fade"
                                    data-type="carousel"
                                    data-nav-container="limoking-banner-images"
                                >
                                    <Slider slidesToShow={isMobile ? 1 : 5} dots={false} arrows={false}>
                                        <div className='item'>
                                            <img
                                                src="upload/banner-11.png"
                                                alt="banner-11"
                                                width={184}
                                                height={100}
                                            />
                                        </div>
                                        <div className='item'>
                                            <img
                                                src="upload/banner-21.png"
                                                alt="banner-21"
                                                width={184}
                                                height={100}
                                            />
                                        </div>
                                        <div className='item'>
                                            <img
                                                src="upload/banner-31.png"
                                                alt="banner-31"
                                                width={184}
                                                height={100}
                                            />
                                        </div>
                                        <div className='item'>
                                            <img
                                                src="upload/banner-41.png"
                                                alt="banner-41"
                                                width={184}
                                                height={100}
                                            />
                                        </div>
                                        <div className='item'>
                                            <img
                                                src="upload/banner-51.png"
                                                alt="banner-51"
                                                width={184}
                                                height={100}
                                            />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="clear" />
                        <div className="clear" />
                    </div>
                </div>
                <div className="clear" />
            </section>
        </div>
    )
}

export default FooterSlider